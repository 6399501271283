<template>
  <!--tr colspan="12">
    <td class="morebtn">
      <template v-if="level > 0 && reqData.partnerLevel != ''" >
        <a @click="getMore" v-if="Number(data.cntBotPartner)">
          <template v-for="i in level">
            <img src="@/assets/img/icon_more.svg" />
          </template>
        </a>
        <template v-else>
           <span class="else"></span>
        </template>
      </template>
    </td>
    <td><a @click="detailOpen('partner', data.memId)">{{data.memId}}</a></td>
    <td>{{data.memNick}}</td>
    <td>{{data.levelName}}</td>
    <td v-if="gameGroupList.includes('casino')"><input type="text" class="w70" name="" v-model="data.casinoRate"></td>
    <td v-if="gameGroupList.includes('slot')"><input type="text" class="w70" name="" v-model="data.slotRate"></td>
    <td v-if="gameGroupList.includes('minigame')"><input type="text" class="w70" name="" v-model="data.miniRate"></td>
    <td>{{ numberWithCommas(data.cntBotPartner) }}</td>
    <td>{{ numberWithCommas(data.cntBotMem) }}</td>
    <td>{{ numberWithCommas(data.cashAmt) }}</td>
    <td>{{ numberWithCommas(data.pointAmt) }}</td>
    <td><button type="button" class="btn-layout btn-blue mr-0" @click="onChangeRate(data)">{{ $t('button.save') }}</button></td>
  </tr-->
  <ul class="partner">
    <li class="morebtn w150" :class="{ 'br0' : reqData.partnerLevel }">
      <template v-if="level > 0 && reqData.partnerLevel != ''" >
        <a @click="getMore" v-if="Number(data.cntBotPartner)" class="plus">
          <template v-if="level">
            <!--img src="@/assets/img/icon_more.svg" /-->
            <i class="fa fa-plus" :class="{'fa-times': inSearchList.length > 0}" aria-hidden="true"></i>
          </template>
        </a>
        <template v-else>
           <span class="else"><i class="fa">•</i></span>
        </template>
      </template>
    <a @click="detailOpen('partner', data.memId)" class="pid">{{data.memId}}</a>
    </li>
    <li :class="{ 'morer' : reqData.partnerLevel }">
      <a @click="detailOpen('partner', data.memId)" :class="data.partnerLevel">{{data.memNick}}</a>
    </li>
    <li :class="data.partnerLevel">
      <template v-if="data.levelName === '총본사'">
         <span class="box">{{ $t('common.seniorA') }}</span>
      </template>
      <template v-else-if="data.levelName === '대본사'">
         <span class="box">{{ $t('common.masterA') }}</span>
      </template>
      <template v-else-if="data.levelName === '부본사'">
         <span class="box">{{ $t('common.juniorA') }}</span>
      </template>
      <template v-else>
       <span class="box">{{data.levelName}}</span>
      </template>
    </li>
    <!-- <li v-if="gameGroupList.includes('casino')"><input type="text" class="w70" name="" v-model="data.casinoRate"></li>
    <li v-if="gameGroupList.includes('slot')"><input type="text" class="w70" name="" v-model="data.slotRate"></li>
    <li v-if="gameGroupList.includes('casino')"><input type="text" class="w70" name="" readonly v-model="data.casinoLosingRate"></li>
    <li v-if="gameGroupList.includes('slot')"><input type="text" class="w70" name="" readonly v-model="data.slotLosingRate"></li>
    <li v-if="gameGroupList.includes('minigame')"><input type="text" class="w70" name="" readonly v-model="data.miniLosingRate"></li> -->

    <template v-for="category in data.memRollingInfo" :key="category.cateCode">
      <template v-if="currentTab === category.cateCode">
        <template v-if="category.detailSetYn === 'N'">
          <li>
             <input type="text" class="w55" :class="{'error': category.errorP}" v-model="category.pointRate" @change="onRateChange(category, 'P')">
             <div class="rate">
                <span class="min">{{ $t('table.head.min') }}:{{ category.minPointRate }}</span>
                <span class="max">{{ $t('table.head.max') }}:{{ category.maxPointRate }}</span>
             </div>
          </li>
          <li>
             <input type="text" class="w55" :class="{'error': category.errorR}" v-model="category.loseRate" @change="onRateChange(category, 'R')">
             <div class="rate">
                <span class="min">{{ $t('table.head.min') }}:{{ category.minLoseRate }}</span>
                <span class="max">{{ $t('table.head.max') }}:{{ category.maxLoseRate }}</span>
             </div>
          </li>
        </template>
        <template v-else>
          <template v-for="rate in category.multipleRateInfo.rateList" :key="rate.rateType">
            <li>
               <input type="text" class="w45" :class="{'error': category.errorP}" v-model="rate.pointRate" @change="onRateChange(rate, 'P', category)">
               <div class="rate">
                  <span class="min">{{ $t('table.head.min') }}:{{ category.minPointRate }}</span>
                  <span class="max">{{ $t('table.head.max') }}:{{ category.maxPointRate }}</span>
               </div>
            </li>
            <li>
               <input type="text" class="w45" :class="{'error': category.errorR}" v-model="rate.loseRate" @change="onRateChange(rate, 'R', category)">
               <div class="rate">
                  <span class="min">{{ $t('table.head.min') }}:{{ category.minLoseRate }}</span>
                  <span class="max">{{ $t('table.head.max') }}:{{ category.maxLoseRate }}</span>
               </div>
            </li>
          </template>
<!--          <li>-->
<!--            <input type="text" class="w70" value="0.0">-->
<!--            <input type="text" class="w70" readonly>-->
<!--          </li>-->
        </template>
      </template>
    </template>

    <li class="gap-5"><input type="checkbox" class="set-switch" @click="switchToggle" v-model="userData.cashSendYn"/> / <input type="checkbox" class="set-switch " @click="switchToggle" v-model="userData.cashReceiveYn"/></li>
    <li class="gap-5"><span>{{ numberWithCommas(data.cntBotPartner) }}</span> / <span>{{ numberWithCommas(data.cntBotMem) }}</span></li>
    <li>{{ numberWithCommas(data.cashAmt) }}</li>
    <li>{{ numberWithCommas(data.pointAmt) }}</li>
    <li><button type="button" class="btn-layout btn-skybl mr-0" v-if="data.partnerLevel !== 'PTN_5'" @click="goToPageNameOpen('partnerCreateView', data)">{{$t('table.body.move')}}</button></li>
    <li><button type="button" class="btn-layout btn-coB mr-0" @click="goToPageNameOpen('userCreateView', data)">{{$t('table.body.move')}}</button></li>
    <li><button type="button" class="btn-layout btn-darkY mr-0" @click="onChangeNewRate(data)">{{$t('common.save')}}</button></li>
  </ul>
  <ul v-if="inSearchList.length > 0" class="partner">
    <li>
      <component :is="component" v-if="inSearchList.length > 0" :key="data.memId"
                 :table="'more'"
                 :currentTab="currentTab"
                 :list="inSearchList"
                 :reqData="reqData"
                 :level="level + 1"
                 :rateCategory="rateCategory"
      />
    </li>
  </ul>

  <div class="modalWrap" v-if="modalActive">
    <div class="modal-item modal2">
      <article class="modal-title">
        <h2>하부 파트너 생성</h2>
        <button class="btn-close" type="button" @click="modalClose"><i class="fa fa-times" aria-hidden="true"></i></button>
      </article>
      <div class="modal-body">
        <article class="fx-item-auto fx gap-5">
          <h4>파트너 정보</h4>
          <table class="part">
            <colgroup>
              <col width="20%"/>
              <col width="30%"/>
              <col width="20%"/>
              <col width="30%"/>
            </colgroup>
            <tr>
              <th>{{ $t('table.head.id') }}</th>
              <td><input class="in" name="newMemId" type="text"/></td>
              <th>비밀번호</th>
              <td><input class="in" name="newMemPass" type="password"/></td>
            </tr>
            <tr>
              <th>닉네임</th>
              <td><input class="in" type="text"/></td>
              <th>출금 비밀번호</th>
              <td><input class="in" type="password"/></td>
            </tr>
            <tr>
              <th>생성파트너등급</th>
              <td></td>
              <th>계좌정보</th>
              <td class="accountInfo">
                <select>
                  <option>BANK1</option>
                  <option>BANK2</option>
                  <option>BANK3</option>
                  <option>BANK4</option>
                </select>
                <input class="in" type="text"/>
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td><input class="in" type="text"/></td>
              <th>전화번호</th>
              <td><input class="in" type="text"/></td>
            </tr>
          </table>
          <h4>{{ $t('table.head.gRateSet') }}</h4>
          <table class="part part2">
            <thead>
              <tr>
                <th colspan="2">{{ $t('common.rolling') }}(%)</th>
                <th colspan="2">{{ $t('common.losing') }}(%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>카지노</td>
                <td>슬롯</td>
                <td>카지노</td>
                <td>슬롯</td>
              </tr>
              <tr>
                <td>최대값 : 0.00<br>최소값 : 0.00</td>
                <td>최대값 : 0.00<br>최소값 : 0.00</td>
                <td>최대값 : 0.00<br>최소값 : 0.00</td>
                <td>최대값 : 0.00<br>최소값 : 0.00</td>
              </tr>
              <tr>
                <td><input></td>
                <td><input></td>
                <td><input></td>
                <td><input></td>
              </tr>
            </tbody>
          </table>
        </article>
      </div>
      <div class="btn-wrap">
        <button class="btn-layout btn-blue" type="button">{{ $t('button.save') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getDateStr, numberWithCommas } from '@/libs/utils'
import { calculateSearch } from '@/api/report'
import { defineAsyncComponent } from 'vue'
import { mapState } from 'vuex'
import { partnerListRateChange, partnerNewList } from '@/api/partner'
import Select from '../Select.vue'
import { ca } from 'date-fns/locale'
import { memberNewRateUpdate, setMultipleRateInfo } from '@/api/member'

export default {
  components: { Select },
  name: 'partnerTr',
  inheritAttrs: false,
  props: [
    'data',
    'reqData',
    'level',
    'currentTab',
    'rateCategory'
  ],
  computed: {
    ca () {
      return ca
    },
    ...mapState([
      'gameGroupList',
      'siteOption'
    ]),
    component () {
      return defineAsyncComponent(() => import('@/components/ui/partner/partnerTable.vue'))
    }
  },
  mounted () {
  },
  data () {
    return {
      more: false,
      inSearchList: [],
      loader: false,
      modalActive: false
    }
  },
  created () {

  },
  watch: {
    data () {
      this.inSearchList = []
      this.more = false
      this.loader = false
    }
  },
  methods: {
    onRateChange (item, type, category) {
      if (category) {
        category['error' + type] = false
      } else {
        item['error' + type] = false
      }

      const max = type === 'P' ? item.maxPointRate : item.maxLoseRate
      const min = type === 'P' ? item.minPointRate : item.minLoseRate
      const value = type === 'P' ? item.pointRate : item.loseRate

      if (Number(value) > Number(max)) {
        if (category) {
          category['error' + type] = true
        } else {
          item['error' + type] = true
        }
        console.log('!!!')
      } else if (Number(min) > Number(value)) {
        if (category) {
          category['error' + type] = true
        } else {
          item['error' + type] = true
        }
      }
    },
    switchToggle (event) {
      const toggle = event.target
      const text = toggle.previousElementSibling
      // console.log(toggle.checked)
      if (text) {
        if (toggle.checked) {
          text.classList.remove('off')
          text.classList.add('on')
        } else {
          text.classList.remove('on')
          text.classList.add('off')
        }
      }
    },
    async onChangeRate (item) {
      const rateList = []

      if (item.memRollingInfo) {
        item.memRollingInfo.forEach(cate => {
          if (cate.detailSetYn === 'Y') {
            if (!cate.errorP && !cate.errorR) {
              const miniParam = {
                memId: item.memId,
                vendorCode: cate.cateCode,
                rateList: cate.multipleRateInfo.rateList
              }

              console.log(miniParam)
            }
          }
        })
      }

      const params = {
        memId: item.memId,
        casinoPR: item.casinoRate,
        slotPR: item.slotRate,
        miniPR: item.miniRate,
        casinoLR: item.casinoLosingRate,
        slotLR: item.slotLosingRate,
        miniLR: item.miniLosingRate
      }

      await partnerListRateChange(params).then(result => {
        if (result.resultCode === '0') {
          alert('요율 수정이 완료되었습니다.')
        } else {
          alert('요율 수정이 실패되었습니다. 잠시 후 다시 시도해주세요.')
        }
      })
    },
    async onChangeNewRate (item) {
      console.log(item)
      this.emitter.emit('Loading', true)
      const isError = false

      let rateList = []

      if (item.memRollingInfo) {
        item.memRollingInfo.forEach(cate => {
          if (!cate.errorP && !cate.errorR) {
            if (cate.detailSetYn === 'Y') {
              if (!cate.errorP && !cate.errorR) {
                const miniParam = {
                  memId: item.memId,
                  vendorCode: cate.cateCode,
                  rateList: cate.multipleRateInfo.rateList
                }

                setMultipleRateInfo(miniParam).then(res => {
                  if (res.resultCode === '0') {
                  } else {
                    alert(res.resultMessage)
                  }
                })
              }
            }

            rateList.push({
              category: cate.category,
              cateCode: cate.cateCode,
              pointRate: cate.pointRate,
              loseRate: cate.loseRate
            })
          } else {
            rateList = []
            return false
          }
        })
      }

      console.log(rateList, isError)

      if (rateList.length > 0 && !isError && item.memRollingInfo.length === rateList.length) {
        const params = {
          siteId: this.siteOption.siteId,
          memId: item.memId,
          rateList
        }

        memberNewRateUpdate(params).then(res => {
          if (res.resultCode === '0') {
            alert('저장 완료되었습니다.')
          } else {
            alert(res.resultMessage)
          }
          this.emitter.emit('Loading', false)
        })
      } else {
        this.emitter.emit('Loading', false)
      }
    },
    goToPageName (name, item) {
      this.$router.push({ name, query: { partnerLevel: item.partnerLevel, partnerId: item.memId } })
    },
    goToPageNameOpen (name, item) {
      const size = {
        width: 740,
        height: 1080
      }
      const position = {
        top: 50,
        left: Math.ceil((window.screen.width - size.width) / 2)
      }

      const routeData = this.$router.resolve({ name, query: { partnerLevel: item.partnerLevel, partnerId: item.memId } })
      window.open(routeData.href, `${name}`, `top=${position.top},left=${position.left},scrollbars=no,resizable=no,width=${size.width},height=${size.height},status=no`)
    },
    async getMore () {
      this.emitter.emit('Loading', true)
      console.log(this.inSearchList)
      if (this.inSearchList.length === 0) {
        const reqData = { ...this.reqData }
        const memId = this.data.memId
        reqData.searchType = 'INNER'
        reqData.memId = memId
        reqData.page = 1

        const res = await partnerNewList(reqData)

        if (res.resultCode === '0') {
          console.log(res)
          this.inSearchList = res.data.list

          this.inSearchList.forEach(item => {
            console.log(item)
            if (item.memRollingInfo.length > 0) {
              item.memRollingInfo.forEach(cate => {
                cate.errorP = false
                cate.errorR = false
              })
            }
          })

          setTimeout(function () {
            this.loader = true
          }.bind(this), 500)
        }
      } else {
        this.inSearchList = []
      }
      this.emitter.emit('Loading', false)
    },
    modalOpen () {
      this.modalActive = true
    },
    modalClose () {
      this.modalActive = false
    }
  }
}
</script>

<style scoped>
.p0 {padding: 0;}
.gap-5 {gap:5px;}
.w150 {width: 150px !important;}
.w45 {width: 45px;padding: 5px;}
.w70 {width: 70px;}
.error {border-color: red;}
.btn-stairOpen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonPositionWrap {
  display: flex;
  justify-content: center;
}
.searchBar > * {
  margin-right: 5px;
}
.searchBar > *:last-child {
  margin-right: 0;
}
.memIdRow {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
}
.btn-childOpen {
  position: relative;
}
.memIdRow span {
  position: relative;
  white-space: nowrap;
}
.moreTable {width: 100%;margin-top: -5px;}
.moreTable .moreTable {border: 0;}
.pid {box-sizing: border-box;padding-left: 20px;/*background: #fff;*/height: 42px !important;min-width: 150px;width: 100%;border: 1px solid #e1e0e0;border-right: 0;display: flex;align-items: center;}
.partner .partner .pid {min-width: 146px;}
.partner .partner .partner .pid {min-width: 126px;}
.partner .partner .partner .partner .pid {min-width: 106px;}
.partner .partner .partner .partner .partner .pid {min-width: 86px;}
/*.partner > li {width: calc(100% - 10px);}
.partner li.morebtn:first-child {width: 200px;}*/

/*.partner .partner li {background: #e8ffe9 !important;}
.partner .partner .partner li {background: #d8eaf5 !important;}
.partner .partner .partner .partner li {background: #fffee1 !important;}
.partner .partner .partner .partner .partner li {background: #e1fffb !important;}
.partner .partner .partner .partner .partner .partner li {background: #ffe1e1 !important;}*/

.partner li:first-child {justify-content: left;}
.partner li:first-child .morebtn > a:first-child,
.partner li:first-child .morebtn .else {margin-left: 20px;}
.partner .partner li:first-child .morebtn > a:first-child,
.partner .partner li:first-child .morebtn .else {margin-left: 40px;}
.partner .partner .partner li:first-child .morebtn > a:first-child,
.partner .partner .partner li:first-child .morebtn .else {margin-left: 60px;}
.partner .partner .partner .partner li:first-child .morebtn > a:first-child,
.partner .partner .partner .partner li:first-child .morebtn .else {margin-left: 80px;}
.partner .partner .partner .partner .partner li:first-child .morebtn > a:first-child,
.partner .partner .partner .partner .partner li:first-child .morebtn .else {margin-left: 100px;}
.partner .partner .partner .partner .partner .partner li:first-child .morebtn > a:first-child,
.partner .partner .partner .partner .partner .partner li:first-child .morebtn .else {margin-left: 120px;}

.mainTable tbody:last-child .moreTable tr:last-child {border-bottom: 0;}
.morebtn {display: flex;align-items: center;}
.morebtn a {cursor: pointer;}
.morebtn a:first-child {justify-content: center;}
.morebtn a:first-child,
.morebtn .else {padding-right: 13px;height: 40px;display: flex;align-items: center;}
.morebtn a .fa,
.else .fa {color: #a7a7a7;border: 2px solid #a7a7a7;border-radius: 4px;padding: 0;width: 12px;height: 12px;display: flex;align-items: center;justify-content: center;font-size: 12px;}
td a {cursor: pointer;display: flex;align-items: center;justify-content: center;color: #0a877b;}

.morer {justify-content: end !important;}
.morer a {padding-right: 10px;}

.partner li .rate {display: flex;flex-direction: column;align-items: baseline;font-size: 10px;margin-left: 3px;width: 58px;}
.partner li .rate .min {color: #eb0000;}
.partner li .rate .max {color: #000fdf;}

.partner {display: flex;margin-top: 5px;}
.partner li {width: 100%;min-height: 40px;background: #fff;font-size: 13px;display: flex;align-items: center;justify-content: center;border-top: solid 1px #e1e0e0;border-bottom: solid 1px #e1e0e0;border-right: 1px solid #e1e0e0;}
.partner li:first-child {background: none;border: 0;border-right: 1px solid #e1e0e0;}
.partner li:nth-child(12) {border-right: solid 1px #e1e0e0;}
.partner .morebtn {position: relative;}
.br0 .pid {padding-left: 10px;}
.br0 {border-right: 0 !important;}
/*.partner .morebtn::before {
  content: '';
  width: 1px;
  height: 47px;
  background: #dbbdbd;
  position: absolute;
  left: 20px;
  top: 0;
}
.partner .partner .morebtn::before {left: 30px;}
.partner .partner .partner .morebtn::before {left: 40px;}
.partner .partner .partner .partner .morebtn::before {left: 50px;}
.partner .partner .partner .partner .partner .morebtn::before {left: 60px;}*/
.partner li:first-child .plus,
.partner li:first-child .morebtn .else {position: relative;}
.partner li:first-child .plus:first-child::before,
.partner li:first-child .else::before {
   content: '';
   width: 1px;
   height: 48px;
   background: #e1e0e0;
   position: absolute;
   left: 20px;
   top: 0;
}
.partner li:first-child .plus:first-child::after,
.partner li:first-child .else::after {
  content: '';
  width: 10px;
  height: 1px;
  background: #e1e0e0;
  position: absolute;
  left: 20px;
  top: 50%;
}

.NORMAL {color: #6d409d;}
.PTN_1 {color: #2b476e;}
.PTN_2 {color: #363b72;}
.PTN_3 {color: #1d505c;}
.PTN_4 {color: #af2a2e;}
.PTN_5 {color: #d98f00;}
.box {width: 38px;border: solid 1px #000;border-radius: 2px;padding: 3px 4px;box-sizing: border-box;color: #fff;font-size: 12px;}
.NORMAL .box {background: #6d409d;}
.PTN_1 .box {background: #2b476e;}
.PTN_2 .box {background: #363b72;}
.PTN_3 .box {background: #1d505c;}
.PTN_4 .box {background: #af2a2e;}
.PTN_5 .box {background: #d98f00;}

.modal-title {
   border-top: 7px solid #eb7a3f;
   border-bottom: solid 1px #d5d5d5;
   background-color: #eee;
   color: #5c5e60;
   font-size: 15px;
   padding: 5px 0;
   box-sizing: border-box;
}

.modal-title .PTtabmenu {
}

.modal-title .PTtabmenu li.current {
  font-weight: bold;
  box-sizing: border-box;
}

.sub-title {
  color: #fff;
  font-size: 18px;
  border-bottom: 0;
  justify-content: center;
}

.modal-item {
  width: 70%;
  margin: 0 auto;
  position: relative;
  margin-top: 350px;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 8px solid #6f7172;
  color: #5c5e60;
}
.modal-item h2 {
  font-size: 25px;
  padding: 7px 0;
  color: #fff;
  font-weight: bold
}
.modal-close {
  transform: translate(-50%, 100%);
}
.modal-item .btn-layout {
  padding: 8px 15px;
  font-size: 16px
}
.ui-check-wrap {
  width: fit-content;
  margin-right: 5px;
}

.btn-wrap {
  gap: 10px;
  width: 100%;
}
.modalWrap .item-title {
   display: flex;
   align-items: center;
   min-width: 100px;
}

.modalWrap .item-title + input {
  text-align: right;
}

.modalWrap .fx-item-auto {
   align-items: center;
   justify-content: space-between;
   height: 42px;
   padding: 0 15px;
   box-sizing: border-box;
   border-bottom: 1px solid #d5d5d5;
}

.modal-body {
}

.modal-body input {
   background: transparent;
   border: 1px solid #d5d5d5;
   box-sizing: border-box;
   border-radius: 3px;
   color: #f06854;
   font-size: 16px;
   height: 27px;
}

.modal-body input[type="text"]:disabled {
  border: 0;
  color: #292a2c;
}

.modal-body select {
   background: transparent;
   border: 1px solid #d5d5d5;
   box-sizing: border-box;
   color: #000;
   font-size: 16px;
   height: auto;
   padding: 5px 15px;
   width: 49%;
}

.modalNotice {
  color: red;
  font-size: 0.8em;
  font-weight: 700;
}
.modal-body .accountInfo {display: flex; gap: 1%;}
.modal-body .accountInfo input { width: 50%; }
.part{
  width: 100%;
  margin-bottom: 20px;
}
.modal-body h4 {font-size: 24px;}
.part td{
  background: none;
  border: 0;
  color: #fff;
}
.part td, .part th {
  border: 1px solid #505050;
  padding: 10px 20px;
}
.part2 input {width: 30%;}
</style>
