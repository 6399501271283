<template>
  <div class="selectWrap" v-if="table === 'main'">
    <span>{{$t('table.head.categorySelect')}} : </span>
    <ul class="tabwrap">
      <template v-if="rateCategory.length">
        <template v-for="item in rateCategory" :key="item.cateCode">
          <li @click="transTab(item.cateCode)" :class="{ 'select' : currentTab === item.cateCode }">{{getCategoryNameText(item.cateCodeName)}}</li>
        </template>
      </template>
    </ul>
    <em @mouseover="showInfo" @mouseout="hideInfo" class="infoicon">?</em>
    <p v-if="onInfo" class="infobox">{{$t('txt.ratecategory')}}</p>
  </div>
  <div :class="{mainTable: table === 'main', moreTable: table ==='more'}">
    <ul v-if="table === 'main'">
      <li class="w150"><a>{{$t('table.head.id')}}</a></li>
      <li>{{$t('table.head.nick')}}</li>
      <li>{{$t('table.head.partnerLevel')}}</li>
      <li>
        {{ $t('common.rolling') }}<br>
        <span>({{getCategoryNameText(currentTabCodeName)}})</span>
      </li>
      <li>
        {{ $t('common.losing') }}<br>
        <span>({{ getCategoryNameText(currentTabCodeName) }})</span>
      </li>
      <template v-if="currentTab === '33'">
        <li>
          {{ $t('common.rolling') }}<br>
          <span>({{ getCategoryNameText(currentTabCodeName) }})</span>
        </li>
        <li>
          {{ $t('common.losing') }}<br>
          <span>({{ getCategoryNameText(currentTabCodeName) }})</span>
        </li>
      </template>
      <li>{{ $t('common.lowmoneytrans') }}<br>({{ $t('common.remi') }}/{{ $t('common.coll') }})</li>
      <!-- <li>{{ $t('common.lowmoneytrans') }} ()</li> -->
      <li>{{$t('table.head.subPartnerCnt')}}<br>/ {{$t('table.head.haveUserNum')}}</li>
      <li>{{$t('table.head.haveAmt')}}</li>
      <li>{{$t('table.head.pointAmt')}}</li>
      <li>{{$t('table.head.subpartner')}}</li>
      <li>{{$t('table.head.userCreate')}}</li>
      <li>{{$t('table.head.option')}}</li>
    </ul>
    <template v-if="list.length > 0" >
      <partner-tr v-for="item in list" :data="item" :reqData="reqData" :level="level" :key="item.memId" :currentTab="currentTab" :rateCategory="rateCategory"/>
    </template>
  </div>
</template>

<script>
import TableHead from '@/components/main/table/Head.vue'
import { mapState } from 'vuex'
import PartnerTr from '@/components/ui/partner/partnerTr.vue'

export default {
  name: 'partnerTable',
  inheritAttrs: false,
  data () {
    return {
      onInfo: false
    }
  },
  components: {
    PartnerTr,
    TableHead
  },
  props: [
    'table',
    'headInfo',
    'list',
    'reqData',
    'level',
    'rateCategory',
    'currentTab'
  ],
  computed: {
    ...mapState([
      'gameGroupList'
    ]),
    currentTabCodeName () {
      const selectedTab = this.rateCategory.find(item => item.cateCode === this.currentTab)
      return selectedTab ? selectedTab.cateCodeName : ''
    }
  },
  methods: {
    transTab (type) {
      this.$emit('onChangeTab', type)
    },
    showInfo () {
      this.onInfo = true
    },
    hideInfo () {
      this.onInfo = false
    },
    getCategoryNameText (categoryName) {
      if (categoryName === 'HC-카지노') {
        return '호텔 카지노'
      }
      return categoryName
    }
  }
}
</script>

<style scoped>
.p0 {padding: 0;}
.w150 {width: 150px !important;}
.w70 {width: 70px;}
.btn-stairOpen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonPositionWrap {
  display: flex;
  justify-content: center;
}
.searchBar > * {
  margin-right: 5px;
}
.searchBar > *:last-child {
  margin-right: 0;
}
.memIdRow {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
}
.btn-childOpen {
  position: relative;
}
.memIdRow span {
  position: relative;
  white-space: nowrap;
}
.moreTable {width: 100%;}
.moreTable .moreTable {border: 0;}
.moreTable td {background: #fff3f3 !important;}
.moreTable .moreTable td {background: #e8ffe9 !important;}
.moreTable .moreTable .moreTable td {background: #d8eaf5 !important;}
.moreTable .moreTable .moreTable .moreTable td {background: #fffee1 !important;}
.moreTable .moreTable .moreTable .moreTable .moreTable td {background: #e1fffb !important;}
.moreTable .moreTable .moreTable .moreTable .moreTable .moreTable td {background: #ffe1e1 !important;}

.mainTable tbody:last-child .moreTable tr:last-child {border-bottom: 0;}
.morebtn {justify-content: center;display: flex;align-items: center;}
.morebtn a {cursor: pointer;}

.mainTable ul {display: flex;box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);border: solid 0.5px #b1b1b1;background-color: #fbfbfb;}
.mainTable ul li {width: 100%;height: 50px;font-size: 13px;text-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);display: flex;align-items: center;justify-content: center;flex-direction: column;}
.mainTable ul li:first-child a {width: 200px;}
.selectWrap {
  display: flex;
  gap: 5px;
  align-items: center;
}
.tabwrap{
  display: flex;
  gap: 5px;
  margin: 10px 0;
}
.tabwrap li {
  padding: 5px 8px;
  border-radius: 5px;
  background: #e4efee;
  color: #82a6a4;
  border: 1px solid #82a6a4;
  font-size: 14px;
  cursor: pointer;
}
.tabwrap li.color {
  background: #ffffff;
}
.tabwrap li.select {
  background: #82a6a4;
  color: #fff;
}
.infoicon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #82a6a4;
  color: #82a6a4;
  background: #e4efee;
  cursor:pointer;
}
.infobox{
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #82a6a4;
  color: #82a6a4;
  background: #e4efee;
  font-size: 13px;
}
</style>
