<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
<!--      <div class="box-ui-check searchbar-content mr-5">-->
<!--        <div class="title">-->
<!--          <i class="fas fa-cog"></i>-->
<!--          <span>{{ $t('table.head.date') }}</span> :-->
<!--        </div>-->
<!--        <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />-->
<!--      </div>-->
         <div class="box-ui-check searchbar-content">
           <div class="title"><span>{{ $t('common.searchOp') }}</span></div>
           <div>
              <select v-model="searchOptionText.option" class="mr-5">
                <option value="memId">{{ $t('searchArea.id') }}</option>
                <option value="memNick">{{ $t('searchArea.nick') }}</option>
              </select>
              <input type="text" v-model="searchOptionText.text" @keydown.enter="loadData(1)" class="mr-5"/>
              <button class="btn-search" type="button" @click="loadData(1)">
                <i class="fas fa-search"></i>
              </button>
           </div>
         </div>
         <div class="search-selector">
           <button type="button" :class="{'select-btn': reqData.partnerLevel === ''}" @click="reqData.partnerLevel = ''" class="" >{{ $t('common.all') }}</button>
           <button type="button" :class="{'select-btn': reqData.partnerLevel === 'PTN_1'}" @click="reqData.partnerLevel = 'PTN_1'" class="" >{{ $t('common.seniorA') }}</button>
           <button type="button" :class="{'select-btn': reqData.partnerLevel === 'PTN_2'}" @click="reqData.partnerLevel = 'PTN_2'" class="" >{{ $t('common.masterA') }}</button>
           <button type="button" :class="{'select-btn': reqData.partnerLevel === 'PTN_3'}" @click="reqData.partnerLevel = 'PTN_3'" class="" >{{ $t('common.juniorA') }}</button>
           <button type="button" :class="{'select-btn': reqData.partnerLevel === 'PTN_4'}" @click="reqData.partnerLevel = 'PTN_4'" class="" >{{ $t('common.agentA') }}</button>
           <button type="button" :class="{'select-btn': reqData.partnerLevel === 'PTN_5'}" @click="reqData.partnerLevel = 'PTN_5'" class="" >{{ $t('common.companyA') }}</button>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container fx-col-sbw">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
        <div class="total-info-wrap"></div>
      </article>
      <div class="table-wrapper">
        <partner-table v-if="outSearchList"
                       :table="'main'"
                       :headInfo="headInfo"
                       :list="outSearchList"
                       :currentTab="currentTab"
                       :reqData="reqData" :key="reqData.endDate"
                       :level="1" :rateCategory="rateCategory" @onChangeTab="onChangeTab"/>
      </div>
    </div>
    <pagination v-if="outSearchList" @goPage="loadData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import { getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import { partnerNewList } from '@/api/partner'
import PartnerTable from '@/components/ui/partner/partnerTable.vue'
import { getRateCategory } from '@/api/common'
import Memo from '@/components/common/memo'
export default {
  name: 'partnerList',
  components: {
    PartnerTable,
    Pagination,
    Memo
  },
  data: function () {
    return {
      modalOn: false,
      toggle: false,
      toggleA: false,
      classes: {
        open: 'fa-minus',
        close: 'fa-plus'
      },
      headInfo: {
        fstColumn: true,
        dataList: ['id', 'nick', 'partnerLenel', 'casinoRoll', 'slotRoll', 'casinoLose', 'slotLose', 'subPartnerCnt', 'bottomuser', 'cashAmt', 'pointAmt', 'modify']
      },
      outSearchList: [],
      pageInfo: {
        page: 0,
        count_per_list: 0,
        tatal_list_count: 0
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        memId: '',
        partnerLevel: '',
        searchType: 'OUTER',
        startDate: '',
        endDate: ''
      },
      dateConfigs: {
        enableTime: false,
        dateFormat: 'Y-m-d'
      },
      tableData: [],
      startDefault: '',
      endDefault: '',
      searchOptionText: {
        option: 'memId',
        text: ''
      },
      rateCategory: [],
      currentTab: ''
    }
  },
  watch: {
    'reqData.partnerLevel' () {
      this.outSearchList = []
      this.loadData()
    },
    rateCategory () {
      if (this.rateCategory.length > 0) {
        this.currentTab = this.rateCategory[0].cateCode
      }
    }
  },
  methods: {
    onChangeTab (type) {
      this.currentTab = type
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
        _date.setHours(0, 0, 0)
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
        _date.setHours(23, 59, 59)
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },

    async loadData (page) {
      this.emitter.emit('Loading', true)
      if (page) {
        this.reqData.page = page
      }

      const reqData = {
        ...this.reqData
      }

      if (this.searchOptionText.text) {
        reqData[this.searchOptionText.option] = this.searchOptionText.text
      }

      const data = await partnerNewList(reqData)
      this.outSearchList = data.data.list
      console.log(this.outSearchList)

      this.outSearchList.forEach(item => {
        if (item.memRollingInfo.length > 0) {
          item.memRollingInfo.forEach(cate => {
            cate.errorP = false
            cate.errorR = false
          })
        }
      })

      if (data.data.pageInfo) {
        this.pageInfo = data.data.pageInfo
      }
      this.emitter.emit('Loading', false)
    },
    async getCategory () {
      await getRateCategory().then(res => {
        console.log(res)
        this.rateCategory = res.data.data.category
        console.log(this.rateCategory)
        // this.rateCategory.push(
        //   {
        //     cateCodeName: '하이로우88',
        //     category: 'minigame',
        //     cateCode: '33'
        //   }
        // )
      })
    }
  },
  async created () {
    this.setStartDate()
    this.setEndDate()
    await this.getCategory()
    await this.loadData()
  }
}
</script>

<style scoped>
.p0 {padding: 0;}
.w70 {width: 70px;}
.btn-stairOpen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonPositionWrap {
  display: flex;
  justify-content: center;
}
.searchBar > * {
  margin-right: 5px;
}
.searchBar > *:last-child {
  margin-right: 0;
}
.memIdRow {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
}
.btn-childOpen {
  position: relative;
}
.memIdRow span {
  position: relative;
  white-space: nowrap;
}
.moreTable {width: 100%;border: 2px solid #e1534e;}
.moreTable .moreTable {border: 0;}
.moreTable td {background: #fff3f3 !important;}
.moreTable .moreTable td {background: #e8ffe9 !important;}
.moreTable .moreTable .moreTable td {background: #d8eaf5 !important;}
.moreTable .moreTable .moreTable .moreTable td {background: #fffee1 !important;}
.moreTable .moreTable .moreTable .moreTable .moreTable td {background: #e1fffb !important;}
.moreTable .moreTable .moreTable .moreTable .moreTable .moreTable td {background: #ffe1e1 !important;}
.mainTable tbody:last-child .moreTable tr:last-child {border-bottom: 0;}
.morebtn {justify-content: center;display: flex;align-items: center;}
.morebtn a {cursor: pointer;}
.select-btn {background-color: #82a6a4;color: #fff;}
button {cursor: pointer}

.mainTable ul {display: flex;}
.mainTable ul li {width: 100%;height: 40px;background: #353535;font-size: 14px;color: #fff;display: flex;align-items: center;justify-content: center;}
</style>
